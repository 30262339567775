<template>
  <b-container box class="main_dashboard__dashboard container_dashboard p-0">
    <b-overlay :show="loadPage" opacity="0.5" no-wrap></b-overlay>
    <b-card class="pt-4 pb-4 p-md-5 card-ppal">
      <b-container>
        <b-row>
          <b-col md="6" lg="6" xl="4" class="mb-4">
            <b-card no-body class="h-100 posts-users">
              <b-card class="mb-4 pt-2 pb-2 total-posts h-50">
                <b-container class="h-100">
                  <b-row class="h-100">
                    <b-col cols="5" align-self="center" class="icon">
                      <img src="@/assets/img/sticky.note.regular.svg" width="25">
                    </b-col>
                    <b-col cols="7" align-self="center" class="text">
                      <h4 class="mb-0">{{posts.length}}</h4>
                      <p class="mb-0">Total Posts</p>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
              <b-card class="pt-2 pb-2 total-users h-50">
                <b-container class="h-100">
                  <b-row class="h-100">
                    <b-col cols="5" align-self="center" class="icon">
                      <img src="@/assets/img/user.laptop.regular.svg" width="30">
                    </b-col>
                    <b-col cols="7" align-self="center" class="text">
                      <h4 class="mb-0">{{users.pagination.total}}</h4>
                      <p class="mb-0">Total Users</p>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-card>
          </b-col>

          <b-col md="6" lg="6" xl="4" class="mb-4">
            <b-card class="pt-4 pb-4 h-100 recent-drafts">
              <b-container>
                <b-row class="mb-3">
                  <b-col cols="2" align-self="center">
                    <img src="@/assets/img/file.alt.pink.svg" width="20">
                  </b-col>
                  <b-col class="text">
                    <h4 class="mb-0">{{recentDrafts.pagination.total}}</h4>
                    <p class="m-0">Recent drafts</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3" v-for="(draft, key) in recentDrafts.data" :key="key" v-if="key < 3">
                  <b-col cols="2" align-self="center">
                    <img src="@/assets/img/check.square.solid.svg" width="20">
                  </b-col>
                  <b-col class="text">
                    <p class="m-0 draft-title">
                      {{draft.title}}
                    </p>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col cols="2" style="align-self: center;">
                    <img src="@/assets/img/check.square.orange.svg" width="20">
                  </b-col>
                  <b-col style="padding-left: 0;">
                    <p class="m-0" style="font: normal normal 500 16px/20px 'Nunito', sans-serif; color: #808080;">
                      Simply dummy text of the printing and typesetting industry.
                    </p>
                  </b-col>
                </b-row> -->
              </b-container>
            </b-card>
          </b-col>

          <b-col md="6" lg="6" xl="4" class="mb-4">
            <b-card class="pt-4 pb-4 h-100 last-logins">
              <h4 class="text-center mb-4">Last logins</h4>
              <b-container>
                <b-row class="mb-3" v-for="(login, key) in lastLogins" :key="key" v-if="key < 3">
                  <b-col cols="2" align-self="center">
                    <img src="@/assets/img/user.robot.solid.svg" width="20">
                  </b-col>
                  <b-col align-self="center" class="text">
                    <p class="m-0">
                      {{login.name + ' ' + login.last_name}}<br>({{login.last_signin}})
                    </p>
                  </b-col>
                </b-row>
                <!-- <b-row class="mb-3">
                  <b-col cols="2" style="align-self: center;">
                    <img src="@/assets/img/user.robot.solid.svg" width="20">
                  </b-col>
                  <b-col style="padding-left: 0; align-self: center;">
                    <p class="m-0" style="font: normal normal 500 16px/20px 'Nunito', sans-serif; color: #808080;">
                      John Doe (2h ago)
                    </p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="2" style="align-self: center;">
                    <img src="@/assets/img/user.robot.solid.orange.svg" width="20">
                  </b-col>
                  <b-col style="padding-left: 0; align-self: center;">
                    <p class="m-0" style="font: normal normal 500 16px/20px 'Nunito', sans-serif; color: #808080;">
                      Martin Davis (yesterday)
                    </p>
                  </b-col>
                </b-row> -->
              </b-container>
            </b-card>
          </b-col>

          <b-col md="12" lg="12" xl="8" order-md="5" class="mb-4 mb-lg-0" order-xl="4">
            <b-card class="p-4 last-post">
              <h4 class="text-center mb-4">Last post</h4>
              <h5 class="mb-4">{{lastPost.title}}</h5>
              <div class="mb-4 body" v-html="lastPost.body && lastPost.body.length > 230 ? lastPost.body.substr(0, 230) + '...' : lastPost.body"></div>
              <p class="by">{{lastPost.user.name + ' ' + lastPost.user.last_name}} ({{lastPost.post_date_format}})</p>
            </b-card>
          </b-col>

          <b-col md="6" lg="6" xl="4" order-md="4" order-xl="5" class="mb-md-4 mb-xl-0">
            <b-card class="p-4 comments h-100">
              <h4 class="text-center mb-4">Comments</h4>
              <p>
                <b>Martin Davis:</b> PageMaker including versions of Lorem 13-02-2022
              </p>
              <p>
                <b>Martin Davis:</b> PageMaker including versions of Lorem 13-02-2022
              </p>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      loadPage: false,
      posts: [],
      users: {
        pagination: {
          total: 0
        }
      },
      recentDrafts: {
        pagination: {
          total: 0
        }
      },
      lastLogins: [],
      lastPost: {
        title: String(),
        body: String(),
        post_date: String(),
        user: {
          name: String(),
          last_name: String(),
        }
      },
    };
  },
  metaInfo(){
    return {
      title: 'Dashboard',
      titleTemplate: '%s | Do Genius On'
    }
  },
  mounted() {
    this.getTotalPosts();
    this.getTotalUsers();
    this.getRecentDrafts();
    this.getLastLogins();
    this.getLastPost();
  },
  methods: {
    getTotalPosts() {
      this.$http.get('post', { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.posts = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getTotalUsers() {
      if(this.checkPermission('List Users - Users')) {
        this.getUsers();
      }else if(this.checkPermission('List Content Creator - Users')) {
        this.getContentCreators();
      }
    },
    getUsers(page){
      this.load = true;
      this.$http.get('user', { params: {page: page}, headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.users = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getContentCreators(page){
      this.$http.get('getContentCreators', { params: {page: page}, headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.users = response.body.data;
        }
        this.load = false;
      }, (error) => {
        console.log(error)
      })
    },
    getRecentDrafts() {
      this.$http.get('getRecentDrafts', { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status == 200) {
          this.recentDrafts = response.body.data;
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getLastLogins() {
      this.$http.get('getLastLogins', { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status == 200) {
          if (response.body.data != null) {
            this.lastLogins = response.body.data.data;
          }
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getLastPost() {
      this.$http.get('getLastPost', { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status == 200) {
          this.lastPost = response.body.data;
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    }
  },
};
</script>
